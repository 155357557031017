import { render, staticRenderFns } from "./pay_config.vue?vue&type=template&id=35019d99&scoped=true&"
import script from "./pay_config.vue?vue&type=script&lang=ts&"
export * from "./pay_config.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35019d99",
  null
  
)

export default component.exports